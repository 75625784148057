import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { mq, getRelative, getP20_1, getP15_2, getTransition } from '@/styles'
import { SliderFlickity } from '@dy/commons/components'

type TypeFilterValue = {
  name: string,
  value: string,
  description?: string,
  media?: {
    type: 'svg',
    alt: string,
    url: string
  }
}

const Section = styled<any>(motion.section)`
  grid-column: 1 / span 6;
  grid-row: 2 / span 1;

  ${mq.greaterThan('tablet')`
    grid-column: 4 / span 9;
  `}

  > div {
    ${mq.greaterThan<any>('tablet')`
      padding: ${getRelative(60, 'desktop')} 0 0 ${getRelative(10, 'desktop')};
      transform: translateX(${({ theme: { col }, productDetailVisible }) => productDetailVisible ? `-${getRelative(col.desktop * 3, 'desktop')}` : 0});
      transition: 300ms width ease-in-out, 200ms transform ${({ theme }) => theme.ease};
      width: 100%;
    `}

    li {
      padding: ${getRelative(40, 'mobile')} ${getRelative(20, 'mobile')};
      width: ${getRelative(414, 'mobile')};

      ${mq.greaterThan('tablet')`
        padding: 0 ${getRelative(158, 'desktop')} 0 0;
        width: ${({ theme: { col }}) => getRelative(col.desktop * 7, 'desktop')};
      `}
    }
  }
`

const Article = styled.article`
  align-items: center;
  display: flex;
  flex-direction: column;

  ${mq.greaterThan('tablet')`
    align-items: flex-start;
    flex-direction: row;
  `}

  figure {
    flex-shrink: 0;
    margin-bottom: ${getRelative(20, 'mobile')};
    user-select: none;
    width: ${getRelative(125, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-bottom: 0;
      margin-right: ${getRelative(38, 'desktop')};
      width: ${getRelative(165, 'desktop')};
    `}
  }

  &.param-marca,
  &.param-brand,
  &.param-marque {
    figure {
      align-items: center;
      background: ${({ theme }) => theme.colors.greendark};
      border-radius: 50%;
      display: flex;
      height: ${getRelative(125, 'mobile')};
      justify-content: center;
      padding: ${getRelative(12, 'mobile')};

      ${mq.greaterThan('tablet')`
        height: ${getRelative(165, 'desktop')};
        padding: ${getRelative(20, 'desktop')};
      `}

      img {
        filter: invert(.95);
        height: 100%;
      }
    }
  }

  .text-wrapper {
    padding-top: ${getRelative(20, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding-top: ${getRelative(20, 'desktop')};
    `}
  }

  h2 {
    color: ${({ theme }) => theme.colors.greendark};
    ${getP20_1()}
    font-weight: bold;
    letter-spacing: -.5;
    margin-bottom: ${getRelative(5, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-bottom: ${getRelative(5, 'desktop')};
    `}
  }

  p {
    color: ${({ theme }) => theme.colors.greendark};
    ${getP15_2()}
    text-align: center;
  }

  h2, p {
    ${mq.greaterThan('tablet')`
      text-align: left;
    `}
  }
`

const Div = styled.div``

const variants = {
  initial: {
    height: 0,
    opacity: 0,
    transition: {
      ...getTransition(),
      delay: 0,
    }
  },
  animate: {
    height: 'auto',
    opacity: 1,
    transition: {
      ...getTransition(),
      duration: .5,
      delay: .4,
    }
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .2,
      delay: 0,
    }
  },
}

const getSlides = (filters) => filters.map((filter, idx) => (
  <li key={idx}>
    <Article className={`param-${filter.param}`}>
      <figure>
        <img src={filter.media.url} alt={filter.media.alt}/>
      </figure>
      <div className='text-wrapper'>
        <h2>{filter.name}</h2>
        <Div dangerouslySetInnerHTML={{ __html: filter.description }} />
      </div>
    </Article>
  </li>
))

type TypeSectionFiltersFeatured = {
  filters?: TypeFilterValue[],
  productDetailVisible: boolean
}

export const SectionFiltersFeatured = memo(({ filters = [], productDetailVisible }:TypeSectionFiltersFeatured) => {
  const Slides = useMemo(() => getSlides(filters), [filters])

  return (
    <Section variants={variants} initial='initial' exit='exit' animate={filters?.length < 1 ? 'exit' : 'animate'} productDetailVisible={productDetailVisible}>
      <SliderFlickity className={'slider--product-card'} loop={true} freeScroll={false} pageDots={false} groupCells={'80%'} cellAlign={'left'} autoPlay={filters?.length > 1}>
        {Slides}
      </SliderFlickity>
    </Section>
  )
})
