import { memo, useEffect, useMemo, useState } from 'react'
import type { FC, Dispatch, SetStateAction } from 'react'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { callAPI } from '@/api'
import { pushAnalyticsEvent, parseDateString } from '@dy/commons/utils'
import { mq, getRelative, createGrid } from '@/styles'
import { usePreOrderCart } from '../../hooks'
import { HeadTag } from '@/components'
import { callLocales } from '@/i18n'
import { Layout, DetailHeader, SectionIngredients, SectionNutritionChart, SectionMoreInfo, SectionRelated } from '../../components/'
import { TypeProductDetailVariant, SectionCategories } from '../../components/shop'

type TypeGridMedia = {
  grid: any
}

type ProductSpecification = {
  name: string,
  value: string | number,
  type: 'weight' | 'energy'
}

type Seo = {
  title: string,
  description: string
}

interface RelatedCategory {
  id: number,
  name: string,
  slug: Slug
}

type Slug = {
  [locale: string]: string
}

type TinyBrand = {
  name: string,
  slug: Slug,
}

interface CategoryTiny {
  [locale: string]: string
}

type TypeProductDetail = {
  bigcommerce_id: number,
  bigcommerce_slug: string,
  name: string,
  slug: Slug,
  sku: string,
  pack?: boolean,
  brand: TinyBrand,
  seo: Seo,
  description?: string,
  filters?: any,
  ingredients: string,
  variants: TypeProductVariant[],
  categories?: RelatedCategory[],
  nutrition_specifications: ProductSpecification[],
  info_specifications: ProductSpecification[],
  labels?: any,
  is_preorder_only?: boolean
  preorder_message?: string
  preorder_release_date?: string
  visible: boolean
}

type TypeProductCard = {
  bigcommerce_id: number,
  bigcommerce_slug: string,
  name: string,
  slug: Slug,
  sku: string,
  pack?: boolean,
  brand: TinyBrand,
  filters: TypeFilter[],
  categories?: CategoryTiny[],
  variant: TypeProductVariant,
  description?: string,
  ingredients?: string,
  labels?: []
}

type TypePrice = {
  price_inc_tax: number,
  retail_price_inc_tax: number,
  price_ex_tax: number,
  retail_price_ex_tax: number,
}

type TypeFilterValue = {
  name: string,
  value: string,
  description?: string,
  media?: {
    type: 'svg',
    alt: string,
    url: string
  }
}

type TypeFilter = {
  type: 'simple' | 'multiple',
  name: string,
  param: string,
  values?: TypeFilterValue[] | []
}

type TypeProductVariant = {
  bigcommerce_id: number,
  name: string,
  prices: TypePrice,
  sku: number,
  stock: number,
  reference_price: number,
  reference_unit: string,
  media: TypeGridMedia[],
  products?: TypeProductCard[],
  stock_warning?: boolean
}

type TypeProductPage = {
  data?: TypeProductDetail,
  variant?: TypeProductDetailVariant,
  setSelectedCard?: any,
  onLoad?: any,
  isLoading?: boolean,
  user?: any,
  isInvalid?: boolean,
  isAuthorize?: boolean
}

type TypeCurrentVariant = [
  TypeProductVariant,
  Dispatch<SetStateAction<TypeProductVariant>>
]

type TypeProductData = [
  TypeProductDetail,
  Dispatch<SetStateAction<TypeProductDetail>>
]

const Main = styled.main`
  ${createGrid()}
  padding: 0;
  position: relative;

  #main-top {
    background-color: ${({ theme }) => theme.colors.background};
    display: block;
    height: 2px;
    opacity: .01;
    position: absolute;
    top: 120px;
    user-select: none;
    width: 100%;

    ${mq.greaterThan('tablet')`
      transform: translateY(-${getRelative(20, 'desktop')});
    `}
  }
  section:not( div > section) {
    margin: unset;
    ${mq.greaterThan('tablet')`
      margin: ${getRelative(120, 'desktop')} 0;
    `}
  }
`

const ProductWrapper = styled.div<any>`
  background-color: ${({ theme }) => theme.colors.white};
  margin: ${({ isPDP }) => isPDP ? '60px 20px 200px' : '60px auto 200px'};
  padding: ${getRelative(20, 'mobile')};
  width: 90vw;
  grid-column: unset;

  ${mq.greaterThan<{isPDP: boolean}>('tablet')`
    border-radius: 20px;
    grid-column: ${({ isPDP }) => isPDP ? '4' : 'unset'};
    margin: ${({ isPDP }) => isPDP ? `${getRelative(120, 'desktop')} ${getRelative(70, 'desktop')} 200px` : `${getRelative(120, 'desktop')} auto 200px`};
    padding: ${getRelative(30, 'desktop')};
    width: ${getRelative(580, 'desktop')};
  `}
`

const PREORDERTRANSLATIONS = {
  'es': 'Fecha de lanzamiento prevista',
  'en': 'Expected release date',
  'fr': "Date de sortie prévue"
}

const NEXT_LOCALE = process.env.NEXT_PUBLIC_LOCALE

const getPreorderMessage = (is_preorder_only, preorder_message, preorder_release_date) => {
  if(!is_preorder_only) return
  const date= parseDateString({dateString : preorder_release_date, NEXT_LOCALE})
  const message = (preorder_message ? preorder_message : PREORDERTRANSLATIONS[NEXT_LOCALE]) + ' ' +  date.toUpperCase()
  return message
}

const isTestPage = (slug) => {
  if(
    !slug ||
    slug.indexOf('test') !== -1 ||
    slug.indexOf('sample') !== -1 ||
    slug.indexOf('prueba') !== -1 ||
    slug.indexOf('abc') !== -1 ||
    slug.indexOf('aaaaaaa') !== -1 ||
    slug.indexOf('dy-') !== -1) {
    return true
  }

  return false
}

export async function getStaticPaths({ locales }) {
  const [data, error] = await callAPI({ type: 'products' })
  if (error) return { paths: [], fallback: true }

  const paths = []
  for(let locale of locales) {
    for(let product of data) {
      const slug = product.slug[locale]

      if(!isTestPage(slug)) {
        const params = { slug, locale }
        if(slug) paths.push({ params })
      }
    }
  }

  return { paths, fallback: 'blocking' }
}

export async function getStaticProps({ params, locale }) {
  const { slug } = params
  const [data, error] = await callAPI({ type: 'product', params: { slug, locale } })
  const [locales, layoutLocale] = await callLocales({ type: 'home', locale })
  const [layoutData, layoutError] = await callAPI({ type: 'layout',  params: { locale } })

  if(error || layoutError) return { notFound: true }

  return {
    props: {
      data,
      layoutLocale,
      locales,
      layout: layoutData
    },
    revalidate: 300, // In seconds
  }
}

const ProductPage: NextPage<TypeProductPage> & { Layout?:FC } = memo(({ data, variant = null, setSelectedCard = null }) => {
  const { locale, pathname, isFallback } = useRouter() || { locale: 'es', pathname: '/product/[slug]', isFallback : true }
  const isPDP = pathname === '/product/[slug]'
  const [productData, setProductData]:TypeProductData = useState(data)
  const { preOrderCart } = usePreOrderCart()
  // const [currentVariant, setCurrentVariant]:TypeCurrentVariant = useState(null)
  const [currentVariant, setCurrentVariant]:TypeCurrentVariant = useState(isPDP ? data?.variants?.[0] : null)
  const [relateds, setRelateds] = useState([])
  const [categoriesMenu, setCategoriesMenu] = useState([])
  const { bigcommerce_id, name, brand, pack, slug, seo, filters, description, nutrition_specifications:nutrition, ingredients, info_specifications:info, variants, labels, visible, is_preorder_only, preorder_message, preorder_release_date } = productData || {}
  
  const preOrderDateMessage = useMemo(() => getPreorderMessage(is_preorder_only, preorder_message, preorder_release_date), [is_preorder_only, preorder_message, preorder_release_date])
  const header = useMemo(() => ({ bigcommerce_id, name, brand, filters, description, variant: currentVariant, variants, visible }), [productData, currentVariant])

  // useEffect for PDP nested in the grid page
  useEffect(() => {
    async function getProductData() {
      const [data, error] = await callAPI({ type: 'product', params: { slug: variant.slug[locale], locale }})
      if(!error) setProductData(data)
    }

    if(variant) getProductData()
  }, [variant])

  // updating currentVariant
  useEffect(() => {
    if(productData) {
      setCurrentVariant(variant ? variants.filter((variantItem => variantItem.bigcommerce_id === variant.variant_id))[0] : variants[0])
    }
  }, [productData])

  useEffect(() => {
    async function getCategoriesMenu() {
      const [data, error] = await callAPI({ type: 'categories' })
      if(!error) setCategoriesMenu(data)
    }

    getCategoriesMenu()
  }, [])

  useEffect(() => {
    async function getRelateds() {
      const [data, error] = await callAPI({ type: 'relateds', params: { slug: productData.slug[locale], locale }})
      if(!error) setRelateds(data)
    }

    if(productData) getRelateds()
  }, [productData])

  useEffect(() => {
    if(currentVariant) {
      pushAnalyticsEvent('view_item', {...productData, ...(variant && { ga_list: variant.ga_list, idx: variant.idx }), variant: currentVariant, isPDP: !!data })
    }

    if(variant && currentVariant) {
      setSelectedCard && setSelectedCard(currentVariant.bigcommerce_id)
    }
  }, [currentVariant])

  if(isFallback) return <></>
  if(!data && isPDP || !data && !variant && !isPDP) return null
  if(!productData || !currentVariant) return null

  return (
    <>
      {data && <HeadTag data={seo} jsonld={{ data, pageType: 'product' }} pathname='product/' slug={slug[locale]} defaultLocale={locale}/>}
      {isPDP ?
      <>
        <Main>
          <span id='main-top'></span>
          <SectionCategories categories={categoriesMenu} productDetailVisible={false}/>
          <ProductWrapper {...(slug && { id: typeof slug === 'string' ? slug : slug[locale] })} isPack={pack} isPDP={isPDP}>
              <DetailHeader data={header} labels={is_preorder_only ? [{ name: 'PRE-ORDER' }, ...labels] : labels} currentVariant={currentVariant} setCurrentVariant={setCurrentVariant} isPack={pack} validForCartType={(preOrderCart && is_preorder_only) || (!preOrderCart && !is_preorder_only)} preOrderCart={preOrderCart} preOrderDateMessage={preOrderDateMessage}/>
              {!pack && nutrition?.length > 0 && <SectionNutritionChart data={nutrition} />}
              {!pack && ingredients?.length > 0 && <SectionIngredients data={ingredients} />}
              {info?.length > 0  && <SectionMoreInfo data={info} />}
              {relateds?.length > 0 && <SectionRelated data={relateds} ga_list={{ id: 'list_relateds_products', name: 'List Relateds Products (slider)'}}/>}
              {currentVariant?.products?.length > 0 && <SectionRelated data={currentVariant.products} ga_list={{ id: 'list_pack_products', name: 'List Pack Products (slider)'}} />}
          </ProductWrapper>
        </Main>
      </>
      :
      <ProductWrapper {...(slug && { id: typeof slug === 'string' ? slug : slug[locale] })} isPack={pack} isPDP={isPDP}>
          <DetailHeader data={header} labels={is_preorder_only ? [{ name: 'PRE-ORDER' }, ...labels] : labels} currentVariant={currentVariant} setCurrentVariant={setCurrentVariant} isPack={pack} validForCartType={(preOrderCart && is_preorder_only) || (!preOrderCart && !is_preorder_only)} preOrderCart={preOrderCart} preOrderDateMessage={preOrderDateMessage} />
        <main>
          {!pack && nutrition?.length > 0 && <SectionNutritionChart data={nutrition} />}
          {!pack && ingredients?.length > 0 && <SectionIngredients data={ingredients} />}
          {info?.length > 0  && <SectionMoreInfo data={info} />}
          {relateds?.length > 0 && <SectionRelated data={relateds} ga_list={{ id: 'list_relateds_products', name: 'List Relateds Products (slider)'}}/>}
          {currentVariant?.products?.length > 0 && <SectionRelated data={currentVariant.products} ga_list={{ id: 'list_pack_products', name: 'List Pack Products (slider)'}} />}
        </main>
      </ProductWrapper>
      }
    </>
  )
})

ProductPage.Layout = Layout
export default ProductPage
