import { memo } from 'react'
import styled from 'styled-components'
import type { TypeFilter } from '@/schema'
import { mq, getRelative, getP20 } from '@/styles'

const Button = styled.button<any>`
  align-items: center;
  border: 0;
  color: ${({ theme }) => theme.colors.greendark};
  display: flex;
  ${getP20()}
  font-family: ${({ theme }) => theme.fonts.platform};
  height: ${getRelative(50, 'mobile')};
  justify-content: space-between;
  opacity: ${({ isActive, isMultiple }) => isMultiple && !isActive ? .5 : 1};
  padding: 0 ${getRelative(40, 'mobile')};
  width: 100%;
  white-space: normal;

  ${mq.greaterThan('tablet')`
    height: ${getRelative(50, 'desktop')};
    padding: 0 ${getRelative(40, 'desktop')};
  `}

  > div {
    position: relative;
  }

  svg {
    display: block;
    opacity: ${({ isActive }) => isActive ? 1 : .4};
    transition: opacity 200ms ${({ theme }) => theme.ease};
    width: ${getRelative(25, 'mobile')};

    ${mq.greaterThan('tablet')`
      width: ${getRelative(25, 'desktop')};
    `}

    path {
      transition: fill 200ms ${({ theme }) => theme.ease};
    }
  }

  figure {
    display: flex;
    height: ${getRelative(12, 'mobile')};
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale(${({ isActive }) => isActive ? 1 : 0});
    transition: transform 200ms ${({ theme }) => theme.ease};
    user-select: none;
    width: ${getRelative(12, 'mobile')};

    ${mq.greaterThan('tablet')`
      height: ${getRelative(12, 'desktop')};
      width: ${getRelative(12, 'desktop')};
    `}
  }
`

const Circle = styled.div<any>`
  background-color: ${({ theme }) => theme.colors.background_green};
  border-radius: 100px;
  height: ${getRelative(9, 'mobile')};
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(${({ isActive }) => isActive ? 1 : 0});
  transition: transform 200ms ${({ theme }) => theme.ease};
  user-select: none;
  width: ${getRelative(9, 'mobile')};

  ${mq.greaterThan('tablet')`
    height: 9px;
    width: 9px;
  `}
`

interface ISvg {
  readonly isActive: boolean
}

const Svg = memo(({ isActive }:ISvg) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='27' height='29' viewBox='0 0 27 29'><path d='M23.75 6.13l-8-4.54c-1.39-.79-3.11-.79-4.5 0l-8 4.54A4.4 4.4 0 0 0 1 9.96v9.08a4.4 4.4 0 0 0 2.25 3.83l8 4.54c1.39.79 3.11.79 4.5 0l8-4.54A4.4 4.4 0 0 0 26 19.04V9.96a4.4 4.4 0 0 0-2.25-3.83' fill={isActive ? '#324931' : '#BDC8BC'} stroke='#324931'/></svg>
  )
})

interface IButtonFilterCheckbox {
  data: TypeFilter,
  onClick: () => void,
  isActive: boolean
}

export const ButtonFilterCheckbox = memo(({ data, onClick, isActive }:IButtonFilterCheckbox) => {
  return (
    <Button onClick={onClick} isActive={isActive} isMultiple={data.type === 'multiple'}>
      {data.name}
      <div>
        <Svg isActive={isActive} />
        {data.type === 'multiple' && data.param !== 'sort' ?
          <figure><img src='/images/svg/i--tick.svg' alt='Icon tick' /></figure>
          :
          <Circle isActive={isActive}/>
        }
      </div>
    </Button>
  )
})
