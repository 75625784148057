import { memo, useMemo, useState } from 'react'
import styled from 'styled-components'
import { ModalFilters, modalFiltersId } from '@/modals'
import { useModal } from '@/hooks'
import { ButtonFilterAccordion, ButtonFilterCheckbox } from '../shop'
import { updateActiveFilters } from '@dy/commons/utils'
import { SliderFlickity } from '@dy/commons/components'
import { mq, getRelative, getP15, getP15_3, hex2Rgba } from '@/styles'
import { usei18n } from '@dy/commons/i18n'

const NEXT_LOCALE = process.env.NEXT_PUBLIC_LOCALE

const Section = styled.section<any>`
  grid-column: 1 / span 6;
  grid-row: 1 / span 1;
  height: ${getRelative(50, 'mobile')};
  position: sticky;
  top: ${getRelative(50, 'mobile')};
  transition: 300ms width ${({ theme }) => theme.ease}, 300ms top ${({ theme }) => theme.ease};
  z-index: 20;
  will-change: top;

  ${mq.greaterThan<any>('tablet')`
    grid-column: 4 / span 9;
    height: ${getRelative(40, 'desktop')};
    top: ${getRelative(80, 'desktop')};
    z-index: 105;
    width: ${({ productDetailVisible }) => productDetailVisible ? 'calc(100% + 25vw)' : '100%'};
  `}
`

const Ul = styled.ul<any>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: ${({ isScrolling, theme: { colors } }) => isScrolling ? `0 4px 40px 0 ${hex2Rgba(colors.black, .1)}` : 'none'};
  display: flex;
  height: ${getRelative(60, 'mobile')};
  justify-content: center;
  transition: 300ms width ease-in-out, 200ms transform ${({ theme }) => theme.ease};
  z-index: 2;
  width: 100vw;

  ${mq.greaterThan<any>('tablet')`
    box-shadow: none;
    height: ${getRelative(60, 'desktop')};
    justify-content: flex-start;
    transform: translate(${({ theme: { col }, productDetailVisible }) => productDetailVisible ? `-${getRelative(col.desktop * 3, 'desktop')}` : 0}, 0);
    width: 100%;
  `}

  li {
    display: none;

    ${mq.greaterThan('tablet')`
      display: inline-block;
      margin-left: ${getRelative(10, 'desktop')};
    `}

    &:first-child {
      display: inline-block;
      height: ${getRelative(50, 'mobile')};

      ${mq.greaterThan('tablet')`
        display: none;
      `}

      button {
        ${getP15_3()}
        height: 100%;
        justify-content: center;
        min-width: ${getRelative(250, 'mobile')};
      }
    }

    &.slider-bar-wrapper {
      width: 100%;
    }
  }
`

const Button = styled.button<any>`
  align-items: center;
  background-color: ${({ theme, isActive }) => hex2Rgba(theme.colors.greendark, isActive ? 1 : 0)};
  border: 1px solid ${({ theme, isActive }) => hex2Rgba(theme.colors.greendark, isActive ? 1 : .3)};
  border-radius: 90px;
  color: ${({ theme, isActive }) => isActive ? theme.colors.background : hex2Rgba(theme.colors.greendark, .5)};
  display: flex;
  ${getP15()}
  font-family: ${({ theme }) => theme.fonts.basier};
  font-weight: 500;
  height: ${getRelative(40, 'mobile')};
  transition: 200ms background-color ${({ ease }) => ease}, 200ms border-color ${({ ease }) => ease}, 200ms color ${({ ease }) => ease};
  padding: 0 ${getRelative(20, 'mobile')};

  ${mq.greaterThan('tablet')`
    height: ${getRelative(40, 'desktop')};
    padding: 0 ${getRelative(20, 'desktop')};
  `}

  &:hover {
    background-color: ${({ theme, isActive }) => hex2Rgba(theme.colors.greendark, isActive ? 1 : 0)};
    border: 1px solid ${({ theme }) => hex2Rgba(theme.colors.greendark, 1)};
    color: ${({ theme, isActive }) => isActive ? theme.colors.background : hex2Rgba(theme.colors.greendark, 1)};
  }
`

type TypeGetFilterButtons = {
  readonly filters: {
    [key: string]: string | string[]
  },
  readonly target: 'filters-bar'|'filters-modal',
  readonly onClickParam?: (param) => void,
  readonly clickedParam?: string | null,
  readonly onClick: (filter, activeFilter) => void
  readonly activeFilters: {
    [key: string]: any
  },
  readonly isBrandPage?: boolean
}

interface ISectionFilters {
  readonly filters: {
    [key: string]: string | string[]
  },
  readonly activeFilters: {
    [key: string]: any
  },
  resultsLength: number | null
  setActiveFilters: any,
  productDetailVisible: boolean,
  isScrolling?: boolean,
  isBrandPage?: boolean
}
const TRANSLATIONS = {
  'en': {
    'brand': 'brand'
  },
  'es': {
    'brand': 'marca'
  },
  'fr': {
    'brand': 'marque'
  }
}
const getFilterButtons = ({ filters, target, onClickParam, clickedParam, onClick, activeFilters, isBrandPage }:TypeGetFilterButtons) => {
  let params = Object.keys(filters)
  if(isBrandPage) {
    const mutatedArray = params.filter((item)=> item !== TRANSLATIONS[NEXT_LOCALE].brand)
    params = mutatedArray
  }
  const item = {
    'filters-bar': (param, filter, idx) => {
      const paramMultipleActive = filter.type === 'multiple' && typeof activeFilters[param] === 'object' && Object.keys(activeFilters[param]).length > 0
      const isActive = filter.type === 'multiple' ? paramMultipleActive : activeFilters[param]
      return (
        <li key={`target-${idx}`}>
          <Button onClick={filter.type === 'multiple' ? () => { onClickParam(param) } : () => { onClick({...filter, param}, activeFilters) }} isActive={param !== 'sort' && isActive}>{filter.name}{paramMultipleActive && param !== 'sort' && ` (${Object.keys(activeFilters[param]).length})`}</Button>
        </li>
      )
    },
    'filters-modal': (param, filter, idx) => (
      <li key={`target-${idx}`}>
        {filter.type === 'simple' ?
          <ButtonFilterCheckbox data={filter} onClick={() => { onClick({...filter, param}, activeFilters) }} isActive={activeFilters[param]}/>
        :
          <ButtonFilterAccordion data={{...filter, param}} activeValues={activeFilters[param]} clickedParam={clickedParam}>
            {filter.values.map((value, idx) => <ButtonFilterCheckbox key={`filter-val-${idx}`} data={{ ...value, param, value, type: 'multiple'}} onClick={() => { onClick({ ...value, param }, activeFilters) }} isActive={activeFilters[param]?.[value.value]}/>)}
          </ButtonFilterAccordion>
        }
      </li>
    )
  }

  return params.map((param, idx) => item[target](param, filters[param], idx))
}

const scrollToTopGrid = () => {
  if(typeof window === 'object') {
    const main = document.getElementById('main-top')
    if(main) setTimeout(() => { main.scrollIntoView({ behavior: 'smooth' }) }, 300)
  }
}

export const SectionFilters = memo(({ filters = {}, activeFilters, resultsLength = 0, setActiveFilters, productDetailVisible = false, isScrolling = null, isBrandPage=false}:ISectionFilters) => {
  const { open } = useModal(modalFiltersId, true, false)
  const { layout } = usei18n()
  const [clickedParam, setClickedParam] = useState(null)

  const onClick = (filter, actives,) => {
    const incomingActives = updateActiveFilters(actives, filter)
    setActiveFilters(incomingActives)
    scrollToTopGrid()
  }

  const onClickParam = (param) => {
    open()
    setClickedParam(param)
  }

  const clearFilters = () => {
    setActiveFilters({ sort: {recommended: true} })
    scrollToTopGrid()
  }

  const FilterButtons = useMemo(() => getFilterButtons({ filters, target: 'filters-bar', onClickParam, activeFilters, onClick, isBrandPage }), [filters, activeFilters, isBrandPage])
  const FilterButtonsModal = useMemo(() => getFilterButtons({ filters, target: 'filters-modal', activeFilters, onClick, clickedParam, isBrandPage }), [filters, activeFilters, clickedParam, isBrandPage])

  const length = useMemo(() => Object.keys(activeFilters).length, [activeFilters])

  return (
    <>
      <Section className='filters-dy-klk' productDetailVisible={productDetailVisible}>
        <Ul productDetailVisible={productDetailVisible} isScrolling={isScrolling}>
          <li><Button onClick={open} isActive={length > 0}>{layout?.filter}{length > 0 && ` (${length})`}</Button></li>
          {FilterButtons.length < 8 ?
            <>{FilterButtons}</>
            :
            <li className='slider-bar-wrapper'>
              <SliderFlickity className={'slider--filters-bar'} loop={false} pageDots={false} buttons={false} freeScroll={true} cellAlign={'left'}>{FilterButtons}</SliderFlickity>
            </li>
          }
        </Ul>
      </Section>
      <ModalFilters filters={FilterButtonsModal} activeFiltersLength={length} resultsLength={resultsLength} clearFilters={clearFilters} />
    </>
  )
})
