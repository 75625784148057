import { memo, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useMediaType, useScrollViewer } from '@/hooks'
import { mq, getRelative, hideScrollbar } from '@/styles'
import type { Slug } from '@/schema'
import ProductPage from '../../pages/product/[slug]'

const Section = styled<any>(motion.section)`
  background-color: ${({ theme }) => theme.colors.white};
  height: calc(100vh - 60px);
  grid-column: 1 / span 6;
  left: 0;
  margin: auto;
  overflow-y: auto;
  position: fixed;
  top: 60px;
  transform: translateX(${({ productDetailVisible }) => productDetailVisible ? 0 : '100vw'});
  transition: 400ms transform ${({ theme }) => theme.ease}, 400ms margin ${({ theme }) => theme.ease}, 300ms top ${({ theme }) => theme.ease}, 300ms height ${({ theme }) => theme.ease};
  right: -10px;
  ${hideScrollbar()}
  width: 90vw;
  z-index: 105;
  will-change: translate, top, height;

  ${mq.greaterThan<{ productDetailVisible: boolean }>('tablet')`
    border-radius: 20px;
    grid-column: 8 / span 5;
    grid-row: 3 / auto;
    left: auto;
    margin-top: ${getRelative(60, 'desktop')};
    padding-bottom: ${getRelative(60, 'desktop')};
    position: sticky;
    top: ${getRelative(145, 'desktop')};
    transform: translateX(${({ theme: { col }, productDetailVisible }) => productDetailVisible ? 0 : `${getRelative(col.desktop * 5, 'desktop')}`});
    z-index: 100;
    width: ${getRelative(580, 'desktop')};
    height: calc(100vh - ${getRelative(160, 'desktop')});
    margin-bottom: 15px;
  `}

  &.plp-klk {
    height: calc(100vh - 60px);
    top: ${getRelative(85, 'mobile')};

    ${mq.greaterThan<{ $showLargeProductDetail: boolean, scrollUp: boolean }>('tablet')`
      height: calc(100vh - ${({ $showLargeProductDetail, scrollUp }) => $showLargeProductDetail ? scrollUp ? getRelative(140, 'desktop') : getRelative(60, 'desktop') : getRelative(160, 'desktop')});
      top: ${getRelative(140, 'desktop')};
      z-index: 1000;
    `}
  }

  &.packs-page-grid {
    ${mq.greaterThan('tablet')`
      grid-row: 1 / auto;
      margin-top: 0;
      height: calc(100vh - ${getRelative(100, 'desktop')});
      top: 85px;

      > button {
        top: ${getRelative(30, 'desktop')};
      }
    `}
  }

  &.not-in-grid {
    ${mq.greaterThan<{ scrollTop: boolean }>('tablet')`
      height: calc(100vh - 100px);
      position: fixed;
      right: ${getRelative(10, 'desktop')};
      top: 85px;
      margin-top: ${({ scrollTop }) => scrollTop ? `${getRelative(40, 'desktop')}` : 0 };

      > div {
        padding-bottom: ${getRelative(10, 'desktop')};
      }

      > button {
        top: ${getRelative(30, 'desktop')};
      }
    `}
  }

  > div {
    margin-top: -${getRelative(40, 'mobile')};
    margin-bottom: 0;
    pointer-events: auto;
    background-color: transparent;

    ${mq.lessThan('tablet')`
      padding-bottom: ${getRelative(60, 'desktop')};
    `}

    ${mq.greaterThan('tablet')`
      margin-top: -${getRelative(40, 'desktop')};
    `}
  }
`

const Button = styled.button<any>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 50%;
  display: flex;
  height: ${getRelative(40, 'mobile')};
  justify-content: center;
  pointer-events: auto;
  position: sticky;
  right: ${getRelative(20, 'mobile')};
  top: ${getRelative(20, 'mobile')};
  width: ${getRelative(40, 'mobile')};
  z-index: 200;
  transform: translateX(${({ productDetailVisible }) => productDetailVisible ? 0 : '100vw'});
  transition: 200ms transform ${({ theme }) => theme.ease}, 200ms margin ${({ theme }) => theme.ease};
  margin-right: 0;
  margin-left: auto;

  ${mq.greaterThan<{ productDetailVisible: boolean }>('tablet')`
    height: ${getRelative(40, 'desktop')};
    right: ${getRelative(30, 'desktop')};
    top: ${getRelative(40, 'desktop')};
    width: ${getRelative(40, 'desktop')};
    transform: translateX(${({ theme: { col }, productDetailVisible }) => productDetailVisible ? 0 : `${getRelative(col.desktop * 5, 'desktop')}`});
  `}

  img {
    height: ${getRelative(16, 'mobile')};
    width: ${getRelative(16, 'mobile')};

    ${mq.greaterThan('tablet')`
      height: ${getRelative(16, 'desktop')};
      width: ${getRelative(16, 'desktop')};
    `}
  }
`

export type TypeProductDetailVariant = {
  variant_id: number,
  slug: Slug,
  ga_list?: {
    id: string,
    name: string
  },
  idx: number
}

type TypeSectionProductDetail = {
  productDetailVisible?: boolean,
  className?: string,
  hideProductDetail: () => void,
  variant: TypeProductDetailVariant,
  setSelectedCard?: any,
  filters?: any,
  showLargeProductDetail?: boolean
}

export const SectionProductDetail = memo(({ className = '', productDetailVisible = false, hideProductDetail, variant = null, setSelectedCard, filters = {}, showLargeProductDetail = false}:TypeSectionProductDetail) => {
  const scrollState = useScrollViewer(80)
  const scrollTop = useMemo(() => scrollState === 'TOP', [scrollState])
  const filtersInSlider = typeof filters === 'object' ? Object.keys(filters)?.length >= 8 : false
  const mediaType = useMediaType()
  const mobile = useMemo(() => mediaType === 'mobile', [mediaType])

  useEffect(() => {
    if(!productDetailVisible || typeof window !== 'object') return

    function onKeyUp(e) {
      if(e.code === 'Escape' || e.key === 'Escape' || e.keyCode === 27) {
        hideProductDetail()
      }
    }

    window.addEventListener('keyup', onKeyUp);
    return () => window.removeEventListener('keyup', onKeyUp);
  }, [productDetailVisible])

  const onMouseDown = ({ target }) => {
		if(!mobile && typeof window === 'object') {
      const section = document.getElementById('detail-page-wrapper')
      if(section) {
				const clickedInSection = section.contains(target)
				if(!clickedInSection) {
					if (target instanceof HTMLAnchorElement || target instanceof HTMLButtonElement) {
						if(!target.classList.contains('product-card') && !target.classList.contains('btn-add') && !target.classList.contains('btn-bin')) hideProductDetail()
					} else if (target instanceof HTMLImageElement) {
            console.log('.')
          } else {
            hideProductDetail()
          }
				}
			}
    }
  }

  useEffect(() => {
    if(!productDetailVisible || typeof window !== 'object') return

    window.addEventListener('mousedown', onMouseDown);
    return () => window.removeEventListener('mousedown', onMouseDown);
  }, [productDetailVisible])
  // useEffect(() => {
  //   if(className !== 'packs-page-grid') {
  //     if(typeof window === 'object' && pathname !== '/') {
  //       const section = document.getElementById('detail-page-wrapper')
  //       if(section) section.scrollTo({ top: 0, behavior: 'smooth' })
  //     }
  //   }
  // }, [variant])

  if(!variant) return <Section className={className} id='detail-page-wrapper'></Section>

  return (
    <Section id='detail-page-wrapper' className={className} $showLargeProductDetail={showLargeProductDetail} productDetailVisible={productDetailVisible} filtersInSlider={filtersInSlider} scrollTop={scrollTop}>
      <Button onClick={hideProductDetail} name='Close details' filtersInSlider={filtersInSlider} productDetailVisible={productDetailVisible} >
        <img src='/images/svg/i--close.svg' alt='Icon close' width='16' height='16' />
      </Button>
      <ProductPage variant={variant} setSelectedCard={setSelectedCard} />
    </Section>
  )
})
