import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { mq, getRelative, getMargin100, hideScrollbar } from '@/styles'
import { CategoriesAccordion } from '@dy/commons/components'
import { myUsualAsLink, packsAsLink } from '../layout/navbar/ModalMenuMobile'
import useCustomer from '@custom-bigcommerce/storefront-data-hooks/use-customer'

const DEFAULT_LOCALE = process.env.NEXT_PUBLIC_LOCALE

const Section = styled(motion.section)<any>`
  display: none;
  ${hideScrollbar()}

  ${mq.greaterThan<any>('tablet')`
    background-color: ${({ theme }) => theme.colors.background};
    height: calc(100vh - 80px);
    display: flex;
    grid-column: 1 / span 3;
    grid-row: 1 / span 4;
    ${getMargin100()}
    overflow-y: auto;
    padding: 0 ${getRelative(30, 'desktop')};
    position: sticky;
    top: 80px;
    transform: translateX(${({ theme: { col }, productDetailVisible }) => productDetailVisible ? `-${getRelative(col.desktop * 3, 'desktop')}` : 0});
    transition: 200ms transform ${({ theme }) => theme.ease};
    z-index: 50;
  `}

  dd {
    a {
      letter-spacing: -0.38px;
    }
  }
`

type TypeSectionCategories = {
  categories: any[],
  productDetailVisible: boolean,
  isBrandPage?: string
  displayPacks?: boolean
}

export const SectionCategories = memo(({ categories, productDetailVisible = false,  isBrandPage = '', displayPacks = false}:TypeSectionCategories) => {
  const { data: userData } = useCustomer()
  const userLogged = useMemo(() => !!userData?.entityId, [userData])

  return (
    <Section productDetailVisible={productDetailVisible}>
      <CategoriesAccordion categories={[
        ...userLogged ? [myUsualAsLink] : [],
        ...categories,
        ...(!isBrandPage && displayPacks && DEFAULT_LOCALE !== 'es') ? [packsAsLink] : [],
      ]} isBrandPage={isBrandPage} />
    </Section>
  )
})
