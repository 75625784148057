import { memo, ReactNode, useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import type { TypeFilter } from '@/schema'
import { mq, getRelative, getTransition, getP20, getP15_5, getP18_1 } from '@/styles'

const Button = styled.button`
  align-items: center;
  /* align-items: flex-start; */
  border: 0;
  color: ${({ theme }) => theme.colors.greendark};
  display: flex;
  ${getP20()}
  font-family: ${({ theme }) => theme.fonts.platform};
  height: ${getRelative(50, 'mobile')};
  justify-content: space-between;
  padding: 0 ${getRelative(40, 'mobile')};
  position: relative;
  width: 100%;
  white-space: normal;

  ${mq.greaterThan('tablet')`
    height: ${getRelative(50, 'desktop')};
    padding: 0 ${getRelative(40, 'desktop')};
  `}

  > div {
    position: relative;

    &.txt-wrapper {
      display: flex;
      flex-direction: column;
      flex-shrink: 1;
      justify-content: space-between;
      text-align: left;
      width: calc(100% - 40px);
    }

    &:last-child {
      flex-shrink: 0;

      span {
        width: 27px;
      }
    }
  }

  .values {
    bottom: -${getRelative(20, 'mobile')};
    color: ${({ theme }) => theme.colors.greendark};
    ${getP15_5()}
    font-family: ${({ theme }) => theme.fonts.basier};
    font-weight: 500;
    letter-spacing: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    transition: 200ms opacity ${({ theme }) => theme.ease};
    user-select: none;
    width: 100%;
    white-space: nowrap;

    ${mq.greaterThan('tablet')`
      bottom: -${getRelative(21, 'desktop')};
      letter-spacing: 0;
    `}

    &.visible {
      opacity: .5;
      user-select: auto;
    }
  }
`

const Div = styled<any>(motion.div)`
  overflow: hidden;

  &[aria-expanded=false] {
    button {
      pointer-events: none;
      user-select: none;
    }
  }

  button {
    ${getP18_1()}
    font-family: ${({ theme }) => theme.fonts.basier};
    font-weight: 500;
    margin-top: ${getRelative(2, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-top: ${getRelative(2, 'desktop')};
    `}
  }
`

const Chevron = styled(motion.span)`
  display: inline-block;
  margin-left: 10px;
  user-select: none;
`

const variants = {
  initial: {
    height: 0,
    opacity: 0,
    transition: {
      ...getTransition(),
      delay: 0,
    }
  },
  animate: {
    height: 'auto',
    opacity: 1,
    transition: {
      ...getTransition(),
      duration: .3,
      delay: .25,
    }
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .2,
      delay: 0,
    }
  },
}

const transition = {
  ease: [.25, .1, .25, 1],
  duration: .3,
  type: 'tween'
}


interface IButtonFilterAccordion {
  activeValues: {
    [key: string]: boolean
  },
  children: ReactNode,
  data: TypeFilter,
  clickedParam?: string | null
}

const getActiveNames = (values, activeValues) => {
  if(typeof activeValues !== 'object') return ''
  return Object.keys(activeValues).map((activeVal, idx) => {
    const name = values?.filter(val => val.value.includes(activeVal))[0]?.name
    return idx < 1 ? name : `, ${name}`
  })
}

export const ButtonFilterAccordion = memo(({ activeValues, children, data, clickedParam = null }:IButtonFilterAccordion) => {
  const ActiveNames = getActiveNames(data.values, activeValues)
  const id = `btn-controls-${data.param}`
  const [isExpanded, setIsExpanded] = useState(clickedParam === data.param ?? false)
  const onClick = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <>
      <Button onClick={onClick} aria-controls={id}>
        <div className='txt-wrapper'>
          {data.name}
          <span className={`values${!isExpanded ? ' visible' : ''}`}>{ActiveNames}</span>
        </div>
        <div>
          <Chevron aria-hidden={true} animate={{ rotate: isExpanded ? 90 : 0}} transition={transition}>
            <img src='/images/svg/i--chevron.svg' alt='Icon chevron' width='6' height='8'/>
          </Chevron>
        </div>
      </Button>
      <Div id={id} aria-expanded={isExpanded} tabIndex={-1} variants={variants} initial='initial' exit='exit' animate={isExpanded ? 'animate' : 'exit'}>
        {children}
      </Div>
    </>
  )
})
